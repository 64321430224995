import { t } from "@lingui/macro";
import { OpenAI } from "openai";

import { useOpenAiStore } from "@/client/stores/openai";

export const openai = () => {
  const { apiKey } = useOpenAiStore.getState();
  if (!apiKey) {
    throw new Error(
      t`Your OpenAI API Key has not been set yet. Please go to your account settings to enable OpenAI Integration.`,
    );
  }

  return new OpenAI({
    apiKey,
    baseURL:"https://api.sgai.cc/v1", // 使用自定义baseUrl或默认值
    dangerouslyAllowBrowser: true,
  });
};
